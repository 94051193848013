import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import Layout from '@components/layout';
import SEO from '@components/seo';

import BodyText from '@components/bodyText';
import Contact from '@components/contact';
import Hero from '../components/hero';

const CookiesLista = ({ data }) => {
  const headLine = data.contentfulPage.headLine;
  const headerTitle = data.contentfulPage.pageHeader;
  const headerText =
    data.contentfulPage.pageHeaderDescription.pageHeaderDescription;

  const bodyText = data.contentfulPage.pageContent[0].bodyText;

  return (
    <Layout newStyle>
      <SEO title={headLine} noIndex />
      <Hero title={headerTitle} subtitleMarkdown={headerText} />
      <BodyText bodyText={bodyText} />
      <Contact />
    </Layout>
  );
};

export default CookiesLista;

CookiesLista.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query CookiesListaQuery {
    contentfulPage(pageUrl: { eq: "/cookies-lista" }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
        childMarkdownRemark {
          html
        }
      }
      pageContactHeader
      pageContent {
        ... on ContentfulDescriptionComponent {
          bodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
